import { BackgroundProcessEnum, buildEnumMapProvider } from 'sc-common';

export const backgroundProcessEnumProvider = buildEnumMapProvider(BackgroundProcessEnum, getEnumText);

function getEnumText(value: BackgroundProcessEnum): string {
    switch (value) {
        case BackgroundProcessEnum.PaperParsingCorrectFromDoc:
            return $localize`Paper Parsing. Correct from Doc`;

        case BackgroundProcessEnum.LoadReferencesFromPdf:
            return $localize`Load References from PDF`;

        case BackgroundProcessEnum.LoadReferencesFromDocOrPdf:
        case BackgroundProcessEnum.LoadReferencesForTechnicalCheck:
            return $localize`Load References from DOC/PDF`;

        case BackgroundProcessEnum.LoadReferencesManually:
            return $localize`Load References manually`;

        case BackgroundProcessEnum.RequestDOIFromCrossref:
            return $localize`Request DOIs from Crossref service`;

        case BackgroundProcessEnum.PrepareFirstCheck:
            return $localize`Export doc/pdf files for the first check`;

        case BackgroundProcessEnum.PrepareDoubleCheck:
            return $localize`Prepare double check`;

        case BackgroundProcessEnum.PrepareTripleCheck:
            return $localize`Checker Prepress generation (Triple)`;

        case BackgroundProcessEnum.ReImportPaperDocuments:
            return $localize`Re-Import doc/pdf files into the system`;

        case BackgroundProcessEnum.PrepareTocRead:
            return $localize`Prepare ToC read`;

        case BackgroundProcessEnum.PrepareAuthorShortRead:
            return $localize`Short generation from DOC files`;

        case BackgroundProcessEnum.PrepareCheckerShortRead:
            return $localize`Short generation from papers data`;

        case BackgroundProcessEnum.GenerateTitleEditorKeywordIndex:
            return $localize`Generate Keyword Index`;

        case BackgroundProcessEnum.GenerateTitleEditorTableOfContents:
            return $localize`Generate Table of Contents`;

        case BackgroundProcessEnum.CheckProjectForPlagiarism:
            return $localize`Check for Plagiarism`;

        case BackgroundProcessEnum.GeneratePrepressPrint:
            return $localize`Generate Prepress Print`;

        case BackgroundProcessEnum.GeneratePrepressEbook:
            return $localize`Generate Prepress EBook`;

        case BackgroundProcessEnum.GenerateShortDoc:
            return $localize`Generate Short Doc`;

        case BackgroundProcessEnum.GenerateShortDb:
            return $localize`Generate Short Db`;

        case BackgroundProcessEnum.GenerateTocFile:
            return $localize`Generate Table of Contents File`;

        case BackgroundProcessEnum.GenerateKeywordIndexFile:
            return $localize`Generate Keyword Index File`;

        case BackgroundProcessEnum.GenerateHomePrintPage:
            return $localize`Generate Home Print Page`;

        case BackgroundProcessEnum.GenerateAuthorIndex:
            return $localize`Generate Author Index`;

        case BackgroundProcessEnum.ReviewerBulkCreate:
            return $localize`Reviewers Bulk Create`;

        case BackgroundProcessEnum.AuthorBulkCreate:
            return $localize`Authors Bulk Create`;

        case BackgroundProcessEnum.GeneratePartPrepressPrint:
            return $localize`Generate Part Prepress Print`;

        case BackgroundProcessEnum.DataPumpCheckAll:
            return $localize`Data Pump: Check All`;

        case BackgroundProcessEnum.DataPumpTitle:
            return $localize`Data Pump: Title`;

        case BackgroundProcessEnum.DataPumpJournal:
            return $localize`Data Pump: Journal`;

        case BackgroundProcessEnum.DataPumpPaper:
            return $localize`Data Pump: Paper`;

        case BackgroundProcessEnum.CheckDoiEmail:
            return $localize`Check DOI Email`;

        case BackgroundProcessEnum.SavedSearch:
            return $localize`Saved Search`;

        case BackgroundProcessEnum.CountryIpRange:
            return $localize`Country Ip Range`;

        case BackgroundProcessEnum.Grid:
            return $localize`Grid`;

        case BackgroundProcessEnum.CounterReportAggregator:
            return $localize`COUNTER Report Aggregator`;

        case BackgroundProcessEnum.SendGridBounces:
            return $localize`SendGrid Bounces`;

        case BackgroundProcessEnum.RelatedPapers:
            return $localize`Related Papers`;

        case BackgroundProcessEnum.PrepareSendGridMarketingCampaigns:
            return $localize`Prepare SendGrid Marketing Campaigns`;

        case BackgroundProcessEnum.UpdateMarketingCampaignFromSendGrid:
            return $localize`Update Marketing Campaign from SendGrid`;

        case BackgroundProcessEnum.CleanUpAllSendGridContacts:
            return $localize`Clean up all SendGrid Contacts`;

        case BackgroundProcessEnum.PdfProcessor:
            return $localize`Pdf Processor`;
    }
}
