import { ServicesEnum, buildEnumMapProvider } from 'sc-common';

export const servicesEnumProvider = buildEnumMapProvider(ServicesEnum, getEnumText);

function getEnumText(value: ServicesEnum): string {
    switch (value) {
        case ServicesEnum.Accounts:
            return $localize`Accounts`;

        case ServicesEnum.AuditTrails:
            return $localize`Audit Trails`;

        case ServicesEnum.ContentManagement:
            return $localize`Content Management`;

        case ServicesEnum.ContentManager:
            return $localize`Content Manager`;

        case ServicesEnum.DataPump:
            return $localize`Data Pump`;

        case ServicesEnum.Default:
            return $localize`Default`;

        case ServicesEnum.EditingTool:
            return $localize`Editing Tool`;

        case ServicesEnum.Mailing:
            return $localize`Mailing`;

        case ServicesEnum.Promotion:
            return $localize`Promotion`;

        case ServicesEnum.Publishing:
            return $localize`Publishing`;

        case ServicesEnum.Search:
            return $localize`Search`;

        case ServicesEnum.Shared:
            return $localize`Shared`;

        case ServicesEnum.Tasks:
            return $localize`Tasks`;

        case ServicesEnum.TechnicalCheck:
            return $localize`Technical Check`;

        case ServicesEnum.CounterReportAggregator:
            return $localize`COUNTER Report Aggregator`;

        case ServicesEnum.MarketingCampaigns:
            return $localize`Marketing Campaigns`;

        case ServicesEnum.PdfProcessor:
            return $localize`Pdf Processor`;
    }
}
